import React, { FC, useCallback, useState } from "react";
import {
  DatePicker,
  Form,
  Input,
  InputNumber,
  Radio,
  Select,
  Switch,
} from "antd";
import { useTranslation } from "react-i18next";
import { ReloadOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import { Currency, TicketStatus, UserStatus } from "@finbackoffice/enums";
import {
  CurrencyIcon,
  CurrencySelect,
  IBetsFilter,
  useWallets,
} from "@finbackoffice/backoffice-core-next";

const { Option } = Select;
const { RangePicker } = DatePicker;
const { Search } = Input;

type IBetsFiltersProps = {
  changeFilter: (filter: any) => void;
  accountStatus?: UserStatus;
  currency: Currency;
  setCurrency: (currency: Currency) => void;
  registrationDateTo?: string;
  onReload?: () => void;
  status: TicketStatus | "";
  isRejectedOnly: boolean;
  isMtsOnly: boolean;
  sportBonusReport: boolean;
  isOriginLang: boolean;
  handleSwitchLang: (checked: boolean) => void;
  filter: IBetsFilter;
};

export const BetsFilters: FC<IBetsFiltersProps> = ({
  changeFilter,
  onReload,
  currency,
  setCurrency,
  status,
  isRejectedOnly,
  isOriginLang,
  sportBonusReport,
  filter,
  handleSwitchLang,
}) => {
  const [t] = useTranslation();
  const [value, setValue] = useState("30D");
  const [minAmount, setMinAmount] = useState<number | null>(null);

  const wallets = useWallets();

  const onChange = (e: any) => {
    setValue(e.target.value);
    switch (e.target.value) {
      case "no_range":
        changeFilter({
          startDate: "",
          endDate: "",
        });
        break;
      case "30D":
        changeFilter({
          startDate: dayjs().add(-30, "days").startOf("day").toISOString(),
          endDate: dayjs().endOf("day").toISOString(),
        });
        break;
    }
  };

  const handleEnterMinAmount = useCallback(() => {
    changeFilter({ min_amount: minAmount });
  }, [changeFilter, minAmount]);

  return (
    <Form className="bets-filter" layout="inline">
      <Form.Item>
        <Select
          defaultValue={status}
          onChange={(value) => changeFilter({ status: value })}
          size={"small"}
        >
          {!isRejectedOnly && (
            <Option value={""}>
              {t("common.bank-account.payment-provider.all")}
            </Option>
          )}
          {Object.values(TicketStatus).map((status) =>
            (!isRejectedOnly && status !== TicketStatus.Rejected) ||
            (isRejectedOnly && status === TicketStatus.Rejected) ? (
              <Option key={status} value={status}>
                {status}
              </Option>
            ) : null
          )}
        </Select>
      </Form.Item>
      <Form.Item>
        <Search
          placeholder={t("bets.search.placeholder")}
          onChange={(e) =>
            !e.target.value ? changeFilter({ searchValue: "" }) : null
          }
          onSearch={(value: string) => changeFilter({ searchValue: value })}
          loading={false}
        />
      </Form.Item>
      <Form.Item
        label={t("player.transactions.column.currency")}
        name="business-currency"
      >
        <CurrencySelect defaultValue={currency} onChange={setCurrency} />
      </Form.Item>
      <Form.Item name="bet-currency">
        <Select
          defaultValue={"all"}
          className="ui-wallets-dropdown"
          onChange={(value) => {
            changeFilter({ wallet: value });
          }}
        >
          {["all", ...wallets].map((c) => (
            <Option key={c} value={c}>
              {c === "all" ? null : <CurrencyIcon currency={c as Currency} />}
              {c === "all"
                ? t("common.bank-account.payment-provider.all-wallets")
                : c}
            </Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item className="flex">
        <Radio.Group onChange={onChange} value={value}>
          <Radio value={"no_range"}>{t("common.no_range")}</Radio>
          <Radio value={"30D"}>30D</Radio>
          {!sportBonusReport && (
            <Radio value={"range"}>
              <RangePicker
                size={"small"}
                value={[
                  filter.startDate ? dayjs(filter.startDate) : undefined,
                  filter.endDate ? dayjs(filter.endDate) : undefined,
                ]}
                placeholder={[
                  t("common.placeholder.start-date"),
                  t("common.placeholder.end-date"),
                ]}
                disabled={value !== "range"}
                onCalendarChange={(dates: any) => {
                  changeFilter({
                    startDate: dates?.[0]
                      ? dayjs(dates[0]).startOf("day").toISOString()
                      : undefined,
                    endDate: dates?.[1]
                      ? dayjs(dates[1]).endOf("day").toISOString()
                      : undefined,
                  });
                }}
              />
            </Radio>
          )}
          {!sportBonusReport && (
            <>
              &ge;
              <InputNumber
                value={minAmount}
                placeholder={t("players.table.column.bet_amount")}
                onChange={(v) => setMinAmount(v)}
                onPressEnter={handleEnterMinAmount}
              />
            </>
          )}
        </Radio.Group>
      </Form.Item>
      {!sportBonusReport && (
        <>
          <i className="vertical-separator" />
          <Form.Item>
            <span>En</span>
            <Switch
              className="same-switch-color"
              checked={isOriginLang}
              onChange={handleSwitchLang}
            />
            <span>{t("common.origin")}</span>
          </Form.Item>
          <i className="vertical-separator" />
          <Form.Item>
            <span>
              <ReloadOutlined onClick={onReload} />
            </span>
          </Form.Item>
        </>
      )}
    </Form>
  );
};
