import { FC, MouseEvent, useCallback, useContext } from "react";
import { Table, TablePaginationConfig, Tag } from "antd";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import { BetsTableDetails } from "./BetsTableDetails";
import { ITicketResponse } from "@finbackoffice/adminbff-client";
import {
  CurrencyFormatterContext,
  IconDeviceMobile,
  IconDeviceDesktop,
  IPagination,
  useBetsValues,
} from "@finbackoffice/backoffice-core-next";
import systemBetVariantsCount from "../../../utils/systemBetVariantsCount";
import { UserInfoTooltip } from "./UserInfoTooltip";
import { Currency, TicketStatus, TicketType } from "@finbackoffice/enums";
import { useUserModal } from "@finbackoffice/backoffice-core-next";
import { usePathname } from "next/navigation";

const { Column } = Table;
type IBetsTableProps = {
  onTableChange: (pagination: IPagination) => void;
  pagination: IPagination;
  disabledUserColumn?: boolean;
  editable?: boolean;
  isMtsOnly?: boolean;
  sportBonusReport: boolean;
  currency: Currency;
  tableData?: { loading: boolean; data: any };
  updateRecord?: (id: string, record: ITicketResponse) => void;
};
export const BetsTable: FC<IBetsTableProps> = ({
  onTableChange,
  tableData,
  pagination,
  disabledUserColumn = false,
  editable = false,
  updateRecord,
  currency,
  isMtsOnly,
  sportBonusReport,
}) => {
  const { openUserModal } = useUserModal();
  const pathname = usePathname();
  const { t } = useTranslation();
  const { formatCurrency } = useContext(CurrencyFormatterContext);
  const { getFieldValueWithCurrency } = useBetsValues();

  const onCellClickHandler = useCallback(
    () => ({
      onClick: (e: MouseEvent<HTMLElement>) => {
        e.stopPropagation();
      },
    }),
    []
  );

  const onUserCellClickHandler = useCallback(
    (record: ITicketResponse) => ({
      onClick: (e: MouseEvent<HTMLElement>) => {
        e.stopPropagation();
        openUserModal({ user_id: record.user_id, redirect: pathname });
      },
    }),
    [openUserModal, pathname]
  );

  const renderBetTypeColumn = (item: any) => {
    const areOutcomesFromMixedSportServices = () => {
      for (let i = 1; i < item.bets.length; i++) {
        if (
          item.bets[0].place_state.status !== item.bets[i].place_state.status
        ) {
          return true;
        }
      }

      return false;
    };

    switch (item.type) {
      case TicketType.Single:
        return (
          <>
            <span>
              {t(
                `player.bets.sportService.${item.bets[0].event_status.toLowerCase()}`
              )}
            </span>
            <span>{t(`player.bets.betType.${item.type.toLowerCase()}`)}</span>
            <span>{item.bets[0].sport_name}</span>
          </>
        );
      case TicketType.System:
        return (
          <>
            <span>
              {areOutcomesFromMixedSportServices()
                ? t("player.bets.sportService.mixed")
                : t(
                    `player.bets.sportService.${item.bets[0].event_status.toLowerCase()}`
                  )}
            </span>
            <span>
              {t(`player.bets.betType.${item.type.toLowerCase()}`)}{" "}
              {item.bets.length
                ? `(${item.system_count || 0}/${item.bets.length})`
                : null}
            </span>
          </>
        );
      case TicketType.Express:
        return (
          <>
            <span>
              {areOutcomesFromMixedSportServices()
                ? t("player.bets.sportService.mixed")
                : t(
                    `player.bets.sportService.${item.bets[0].event_status.toLowerCase()}`
                  )}
            </span>
            <span>
              {t(`player.bets.betType.${item.type.toLowerCase()}`)}{" "}
              {item.bets.length}
            </span>
          </>
        );
      default:
        return null;
    }
  };

  const renderBetColumn = (item: any) => {
    switch (item.type) {
      case TicketType.Single:
        return (
          <>
            <p>{`${item.bets[0].home_team} - ${item.bets[0].away_team}`}</p>
            <p>
              <span>{t(`player.bets.pick`)}</span>
              {`: ${item.bets[0].outcome_name}`}
              {" / "}&nbsp;
              <p>
                <span>{t(`player.bets.odds`)}</span>
                {`: ${item.odds}`}
              </p>
            </p>
          </>
        );
      case TicketType.System:
        return (
          <p>
            {t(`player.bets.picks`)}: {item.bets.length}
            {", "}
            {t("player.bets.var")}:{" "}
            {systemBetVariantsCount(item.bets.length, item.system_count)}
            {", "}
            {t("player.bets.unit_stake")}:{" "}
            {formatCurrency(
              parseFloat(item.amount) /
                systemBetVariantsCount(item.bets.length, item.system_count),
              item.currency,
              true,
              false
            )}
          </p>
        );
      case TicketType.Express:
        return (
          <p>
            <span>{t(`player.bets.picks`)}</span>: {item.bets.length}
            {" /"}&nbsp;
            <p>
              <span>{t(`player.bets.odds`)}</span>: {item.odds}
            </p>
            <span className="bets-table-events-more">/ ...</span>
          </p>
        );
      default:
        return null;
    }
  };

  return (
    <Table
      onChange={(pagination: TablePaginationConfig) =>
        onTableChange({
          current: pagination.current!,
          pageSize: pagination.pageSize!,
        })
      }
      scroll={{ y: "calc(100% - 35px)" }}
      className="bets-table"
      bordered
      loading={tableData?.loading || false}
      pagination={pagination}
      dataSource={tableData?.data || [{ key: 1 }, { key: 2 }, { key: 3 }]}
      rowKey={(record) => record.id}
      expandable={{
        expandRowByClick: true,
        expandedRowRender: (record: any) => (
          <BetsTableDetails
            data={record}
            editable={editable}
            isMtsOnly={isMtsOnly}
            updateRecord={updateRecord}
          />
        ),
      }}
    >
      <Column
        className="bets-created-date-column"
        title={t("bets.created")}
        dataIndex="created"
        key="created"
        width="70px"
        onCell={onCellClickHandler}
        render={(value, record: ITicketResponse) => (
          <>
            <span>{dayjs(record.created_at).format("MM/DD/YY")}</span>
            <span>{dayjs(record.created_at).format("HH:mm:ss")}</span>
          </>
        )}
      />

      <Column
        className="bets-settled-date-column"
        title={t("sport-control.game.market_group.status.settled")}
        dataIndex="settled"
        key="settled"
        width="74px"
        onCell={onCellClickHandler}
        render={(value, record: ITicketResponse) =>
          record.closed_at ? (
            <>
              <span>{dayjs(record.closed_at).format("MM/DD/YY")}</span>
              <span>{dayjs(record.closed_at).format("HH:mm:ss")}</span>
            </>
          ) : (
            "- -"
          )
        }
      />
      {disabledUserColumn ? null : (
        <Column
          className="table-username-td"
          title={`${t("players.table.column.userid")} / ${t(
            "login.login-form.button.text"
          )}`}
          dataIndex="username"
          key="username"
          width="118px"
          onCell={onUserCellClickHandler}
          render={(value, record: ITicketResponse) => (
            <>
              <span>
                {
                  <span>
                    {record.user_id} /
                    <br />
                    <span
                      style={{ marginTop: 3, display: "block" }}
                      title={record.username}
                    >
                      {record.username}
                    </span>
                  </span>
                }
                <UserInfoTooltip user_id={record.user_id} currency={currency} />
              </span>
              <div>{(record.origin || "").replace(/^https?:\/\//, "")}</div>
            </>
          )}
        />
      )}
      {!sportBonusReport && (
        <Column
          className="bets-type-column"
          title={t("player.transactions.column.operation")}
          dataIndex="type"
          key="type"
          width="100px"
          onCell={onCellClickHandler}
          render={(value, record: ITicketResponse) =>
            renderBetTypeColumn(record)
          }
        />
      )}
      <Column
        title={t("bets.ticket_id")}
        dataIndex="ticketId"
        key="ticketId"
        width={sportBonusReport ? "90px" : "140px"}
        onCell={onCellClickHandler}
        render={(value, record: ITicketResponse) =>
          (
            <>
              <div>Short id:</div>
              {record.short_id}
            </>
          ) || "BR210524XO4JWF"
        }
      />
      <Column
        title={t("common.device_ip")}
        dataIndex="client_ip"
        key="client_ip"
        className="bets-device-ip-column"
        width={sportBonusReport ? "90px" : "140px"}
        onCell={onCellClickHandler}
        render={(item, record: any) => (
          <>
            {record.is_mobile ? <IconDeviceMobile /> : <IconDeviceDesktop />}
            <span>{item}</span>
            <div>{record.country}</div>
          </>
        )}
      />
      <Column
        className="bets-table-events"
        title={t("bets.event_market")}
        dataIndex="events"
        key="events"
        width="auto"
        render={(value, record: ITicketResponse) => (
          <>
            {Number(record.bonus) > 0 && (
              <Tag color="#67BB77" className="bets-table-bonus-tag">
                {`${t("bets.label.bonus")}: ${Number(record.bonus)}%`}
              </Tag>
            )}
            {renderBetColumn(record)}
          </>
        )}
      />
      <Column
        title={t("common.title.curr")}
        dataIndex="curr"
        key="curr"
        width="54px"
        onCell={onCellClickHandler}
        render={(value, record: ITicketResponse) => record.currency}
      />
      <Column
        className="bets-stake-win-column"
        title={t("bets.stake_pos_win")}
        dataIndex="stake_win"
        key="stake_win"
        width="100px"
        onCell={onCellClickHandler}
        render={(value, record: ITicketResponse) => {
          return (
            <>
              {formatCurrency(record.amount, record.currency)}
              {currency !== record.currency ? (
                <span>
                  {getFieldValueWithCurrency({
                    ticket: record,
                    fieldName: "amount",
                    filterCurrency: currency,
                  })}{" "}
                  /
                  <br />
                  {getFieldValueWithCurrency({
                    ticket: record,
                    fieldName: "possible_win",
                    filterCurrency: currency,
                  })}
                </span>
              ) : (
                "/"
              )}
              <span>
                {formatCurrency(record.possible_win, record.currency)}
              </span>
            </>
          );
        }}
      />
      <Column
        className="bets-result-column"
        title={t("common.result")}
        dataIndex="result"
        key="result"
        width="120px"
        onCell={onCellClickHandler}
        render={(value, record: ITicketResponse) =>
          record.status === TicketStatus.Accepted ? (
            "- -"
          ) : (
            <>
              <div>{formatCurrency(record.actual_win, record.currency)}</div>
              <span>
                {getFieldValueWithCurrency({
                  ticket: record,
                  fieldName: "actual_win",
                  filterCurrency: currency,
                })}
              </span>
            </>
          )
        }
      />
      <Column
        colSpan={2}
        title={t("players.table.column.status")}
        dataIndex="status"
        className="bets-status-column"
        key="status"
        width="60px"
        onCell={onCellClickHandler}
        render={(value, record: ITicketResponse) => (
          <>
            {record.status ? (
              <i
                title={record.status.toLowerCase()}
                className={`bet-status bet-status-${record.status.toLowerCase()}`}
              />
            ) : (
              <i className="bet-status bet-status-lose" />
            )}
            {record.admin_name && (
              <div className="bet-status-td-manual">
                <i>M</i>
                <span title={record.admin_name}>{record.admin_name}</span>
              </div>
            )}
          </>
        )}
      />
    </Table>
  );
};
