import { FC, ReactElement, useEffect, useState } from "react";
import { Spin } from "antd";
import dayjs from "dayjs";
import {
  IPagination,
  useBets,
  IBetsFilter,
  DEFAULT_BETS_PAGINATION,
  useCurrencyOptions,
} from "@finbackoffice/backoffice-core-next";
import { BetsFilters } from "./BetFilters";
import { BetsTable } from "./BetsTable";
import { Currency, TicketStatus } from "@finbackoffice/enums";
import "./bets.sass";

type IBetsComponentProps = {
  playerId?: number;
  editable?: boolean;
  showFilters?: boolean;
  search?: string;
  topPagination?: IPagination;
  isRejectedOnly?: boolean;
  isMtsOnly?: boolean;
  sportBonusReport?: boolean;
};

const BetsComponent: FC<IBetsComponentProps> = ({
  playerId,
  editable,
  showFilters = true,
  search = "",
  topPagination,
  isRejectedOnly = false,
  isMtsOnly = false,
  sportBonusReport=false
}): ReactElement => {
  const [filter, setFilter] = useState<IBetsFilter>({
    status: "",
    startDate: dayjs().add(-30, "days").startOf("day").toISOString(),
    endDate: dayjs().endOf("day").toISOString(),
    wallet: "all",
    ...(search && { searchValue: search }),
    ...(isRejectedOnly && { status: TicketStatus.Rejected }),
  });
  const { defaultCurrency } = useCurrencyOptions();
  const [currency, setCurrency] = useState<Currency>(
    isMtsOnly ? Currency.EUR : defaultCurrency
  );

  const {
    loadBetsData,
    pagination,
    setPagination,
    isOriginLang,
    handleSwitchLang,
    betsData,
    totalItems,
    updateRecord,
  } = useBets({ topPagination, playerId, filter, isMtsOnly, sportBonusReport });

  useEffect(() => {
    loadBetsData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagination, filter, isOriginLang]);

  return (
    <>
      {showFilters ? (
        <BetsFilters
          changeFilter={(filterData) => {
            setFilter({ ...filter, ...filterData });
            setPagination(topPagination || DEFAULT_BETS_PAGINATION);
          }}
          isRejectedOnly={isRejectedOnly}
          isMtsOnly={isMtsOnly}
          sportBonusReport={sportBonusReport}
          status={filter.status}
          currency={currency}
          setCurrency={setCurrency}
          onReload={loadBetsData}
          isOriginLang={isOriginLang}
          handleSwitchLang={handleSwitchLang}
          filter={filter}
        />
      ) : null}

      <Spin spinning={false}>
        <BetsTable
          tableData={betsData}
          isMtsOnly={isMtsOnly}
          sportBonusReport={sportBonusReport}
          currency={currency}
          pagination={{ ...pagination, total: totalItems }}
          onTableChange={setPagination}
          updateRecord={updateRecord}
          disabledUserColumn={!!playerId}
          editable={editable}
        />
      </Spin>
    </>
  );
};

export default BetsComponent;
